import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/modules/shared/model/user';
import { AdminModalComponent } from '../../../../shared/components/modals/admin-modal/admin-modal.component';
import { environmentOauth } from 'src/environments/environment.oauth';
import { AuthService } from 'src/app/modules/shared/services/auth/auth.service';

@Component({
  selector: 'wave-eduxible-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  closeResult = '';
  user!: User;
  isCollapsed: any;

  constructor(private router: Router, private modalService: NgbModal, private authService: AuthService) {}
  
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')!);    
  }

  logOut(): void {    
    localStorage.clear();
    if(environmentOauth.authType == 'OAUTH'){
      this.authService.logout();
    }    
  }  
  
}
